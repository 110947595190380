import * as z from 'zod'

const maxDecimal = 99_999_999.99

// TODO: Remove those that already included in react-commons library

export const EmptyResponse = z.union([z.void(), z.literal('')])
export type EmptyResponse = z.infer<typeof EmptyResponse>

export const UnknownResponse = z.unknown()
export type UnknownResponse = z.infer<typeof UnknownResponse>

export const EmptyObject = z.record(z.never())
export type EmptyObject = z.infer<typeof EmptyObject>

export const PositiveNonZeroInt = z.number().int().min(1).max(2147483647)
export type PositiveNonZeroInt = z.infer<typeof PositiveNonZeroInt>

export const PositiveInt = z.number().int().min(0).max(2147483647)
export type PositiveInt = z.infer<typeof PositiveInt>

export const PositiveDecimal = z.string().nonempty()
export type PositiveDecimal = z.infer<typeof PositiveDecimal>

// TODO: Why do we need 2 types for decimal entity? Can we join them?
export const PositiveDecimalNumber = z.number().min(0).max(maxDecimal)
export type PositiveDecimalNumber = z.infer<typeof PositiveDecimalNumber>

export const Price = z.string().nonempty()
export type Price = z.infer<typeof Price>

// TODO: Why do we need 2 types for decimal entity? Can we join them?
// TODO: I would prefer using js-big-decimal for money
export const PriceNumber = z.number().min(0).max(maxDecimal)
export type PriceNumber = z.infer<typeof PriceNumber>

export const UrlString = z.string().min(12).max(1024)
export type UrlString = z.infer<typeof UrlString>

export const EmailAddress = z
  .string()
  .min(4)
  .max(256)
  .regex(/.+@.+\.[a-zA-Z]+/)
export type EmailAddress = z.infer<typeof EmailAddress>

// TODO: Add pattern list when it'll be added on server-side
export const PhoneNumber = z.string().min(4).max(20)
export type PhoneNumber = z.infer<typeof PhoneNumber>

export const DisplayName = z.string().min(4).max(50)
export type DisplayName = z.infer<typeof DisplayName>

export const ShortDescription = z.string().min(1).max(200)
export type ShortDescription = z.infer<typeof ShortDescription>

export const FreeFormText = z.string().min(1).max(3000)
export type FreeFormText = z.infer<typeof FreeFormText>

export const Password = z.string().min(6).max(16)
export type Password = z.infer<typeof Password>

// TODO: Remove objects that ara already defined in react-commons library

export const IdObject = z.object({
  id: z.string()
})
export type IdObject = z.infer<typeof IdObject>

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const TranslatedString = (Translation: z.ZodString) =>
  z.object({
    eng: Translation,
    est: Translation,
    rus: Translation
  })
export type TranslatedString = z.infer<ReturnType<typeof TranslatedString>>

export const AllowedAction = z.enum(['EDIT', 'DELETE'])
export type AllowedAction = z.infer<typeof AllowedAction>

export const Language = z.enum(['ENG', 'EST', 'RUS'])
export type Language = z.infer<typeof Language>

export const PaginationInfo = z.object({
  pageSize: z.number(),
  selectedPage: z.number(),
  totalItems: z.number()
})
export type PaginationInfo = z.infer<typeof PaginationInfo>

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const page = <T extends z.ZodTypeAny>(itemsType: T) =>
  z.object({
    items: itemsType.array(),
    pagination: PaginationInfo
  })
