import { useTranslation } from 'react-i18next'
import { isClientErrorTranslation } from '@digital-magic/react-common/lib/errors'
import { hasValue } from '@digital-magic/ts-common-utils'
import { RequestErrorHandler, RequestErrorMapper } from '@api/types'
import { defaultRequestErrorMapper } from '@api/utils'
import { useSnackbar } from 'notistack'

export const useDefaultErrorHandler = (
  requestErrorMapper: RequestErrorMapper = defaultRequestErrorMapper
): RequestErrorHandler => {
  const snackbar = useSnackbar()
  const { t } = useTranslation()

  return (e) => {
    const error = requestErrorMapper(e)
    if (isClientErrorTranslation(error)) {
      if (hasValue(error.messageOpts)) {
        snackbar.enqueueSnackbar(t(error.messageKey, error.messageOpts))
      } else {
        snackbar.enqueueSnackbar(t(error.messageKey))
      }
    } else {
      snackbar.enqueueSnackbar(error.message)
    }
  }
}
